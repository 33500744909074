<template>
	<b-row>
		<b-col cols="12">
			<b-card-code title="Create New Site">

				<b-card-text>
					<span>Ex. - http://example.com OR http://www.example.com</span>
				</b-card-text>

				<validation-observer ref="add-new-site-form">
					<b-overlay :show="isFetchingRecordsFromServer" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">
						<b-form>
							<b-row>
								<b-col md="6">
									<validation-provider #default="{ errors }" name="Site URL" rules="required|url" :custom-messages="{ required: 'Please enter site URL'}">
										<b-form-group label="Site URL" label-for="siteURL">
											<b-form-input lg id="siteURL" v-model="siteURL" placeholder="Site URL (www.example.com)" />
											<small class="text-danger">{{ errors[0] }}</small>
										</b-form-group>
									</validation-provider>
								</b-col>
								<b-col md="6">
									<validation-provider #default="{ errors }" name="Timezone" rules="required" :custom-messages="{ required: 'Please enter Timezone'}">
										<b-form-group label="Select Timezone" label-for="timeZone">
											<Select2 id="timeZone" v-model="timeZone" :options="timezoneArray" name="timezone" @select="selectDateTypeChangeEvent($event)" placeholder="Select Timezone" />
											<small class="text-danger">{{ errors[0] }}</small>
										</b-form-group>
									</validation-provider>
								</b-col>
								<b-col cols="12">
									<b-button variant="primary" type="submit" @click.prevent="validationForm">
										Submit
									</b-button>
								</b-col>
							</b-row>
						</b-form>
					</b-overlay>
				</validation-observer>

				<template #code>
					{{ codeSimple }}
				</template>

			</b-card-code>
		</b-col>
	</b-row>

</template>

<script>
	import BCardCode from '@core/components/b-card-code'
	import { ValidationProvider, ValidationObserver } from 'vee-validate'
	import { BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCardText, BOverlay } from 'bootstrap-vue'
	import { required } from '@validations'
	import { codeSimple } from './code'
	import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

	export default {
		components: {
			BCardCode, ValidationProvider, ValidationObserver, BCardText, BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BOverlay, ToastificationContent,
		},
		data() {
			return {
				emailValue: '',
				siteURL: '',
				codeSimple,
				required,
				timezoneArray: [],
				timeZone: null,
				selectedTimeZoneId: null,
				isFetchingRecordsFromServer: false,
			}
		},
		created() {
			this.fetchTimeZoneList();
		},
		methods: {
			fetchTimeZoneList() {
				this.$globalAPI.get('timezone/list').then((response) => {
					response = response.data;
					if (response.status) {
						for (const [key, val] of Object.entries(response.timezones)) {
							this.timezoneArray.push({
								'id': key,
								'text': val + ' - ' + key,
								'timeZoneName' : val,
							});
						}
					}
				});
			},
			selectDateTypeChangeEvent({ id, text, timeZoneName }) {
				this.selectedTimeZoneId = timeZoneName
				this.timeZone = id;
			},
			validationForm() {
				this.$refs['add-new-site-form'].validate().then(success => {
					if (success) {
						this.isFetchingRecordsFromServer = true;
						this.$siteAPI.add({
							'user_id': this.$root.authUserObject.id,
							'siteURL': this.siteURL,
							'timeZone': this.selectedTimeZoneId,
						}).then((response) => {
							this.isFetchingRecordsFromServer = false;
							response = response.data;
							if (response.status) {
								this.changeAppSite(response.site)
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'success',
										text: response.message,
									},
								});
							} else {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'danger',
										text: response.message,
									},
								});
							}
						});
					}
				})
			},
			/* CHANGE SESSION AND CURRENT SITE */
			changeAppSite(site) {
				this.$root.appSiteObject = site;
				this.selectedSiteObject = site;
				this.$siteAPI.setSession({
					'site_id': site.siteId,
				}).then((response) => {
					response = response.data;
					this.$store.commit('app/TOGGLE_OVERLAY', false); /* FOR BLUR BACKEND */
					this.searchQuery = ''; /* EMPTY SEARCH BOX */
					this.$router.push('add-new-capture'); /* Refresh current page for set site to globle */
					EventBus.$emit('eventChangeAppSite', true); /* Fetch current site data */
				});
			},
		},
	}
</script>